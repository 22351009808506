/** @jsx jsx */
import { FC, useContext, lazy, Suspense, useState } from 'react'
import { Flex, Box, jsx, Text, Container, Image } from 'theme-ui'

import Halo from '../components/halo'
import PageFrame from '../components/page'
import { WalletContext } from '../contexts/dapp'

import { useDialogState, Dialog, DialogBackdrop, DialogDisclosure } from 'reakit/Dialog'

import Loadable from '@loadable/component'
const LazyConnect = Loadable(() => import('../components/connect'))

export interface ServicesListType {
  title?: string
  subhead?: string
  image?: string
  desc?: string
  items?: any
}

export const ServicesList: ServicesListType[] = [
  {
    title: 'Branding',
    subhead: 'Design Research',
    image: '',
    desc:
      'Professional branding adds value to the enterprise, and gives the company an established, trustworthy, and confident face. Consistent and clear marketing identity makes the brand easy to recognize, and add to its cohesiveness, connecting all the various aspects of marketing together. Brand recognition is vital to generating sales, and building a unique brand identity sets you apart from competitors. Proper branding makes your enterprise more appealing to your audience. Money invested on branding is seen by customers as a measure of the quality and commitment that a brand brings to the table. The money, time, and effort put into branding could mean the difference between a sealed deal and a broken one for any enterprise.',
    items: [
      'Analyze cliente business',
      'Branding Strategy',
      'Research opportunities',
      'Create information architecture',
      'Study the target audience',
    ],
  },
  {
    title: 'Create engaging user experiences',
    image: '',
    desc:
      'Product design blends the goals of the business with the needs of the user to create a selling point that is consistently successful. For successful product design, it is important to understand the needs and requirements of the end-user, and cater to them. Knowledge of customers needs, wants, and frustrations are taken into account when designing a product, which makes it much more valuable to the user. Product designing involves a lot of ideation, imagination, ane creation. Building the perfect product that satisfies user needs with minimal hitches add value to the name of the brand, and builds a stable customer base.',
    subhead: 'UX, UI, & PRODUCT DESIGN',
    items: ['Interaction Design', 'UX Research', 'Design Systems', 'Visual Design'],
  },
  {
    title: 'Front-end Development',
    image: 'trainingImage',
    desc:
      'Conducting Design Research allows our team to validate that the problem we’re solving for is worth the time and money to solve for it. It develops a fuller understanding of the problem for the team from the eyes of the people using the product. Having that validation and team understanding sets the stage for the team to find innovative solutions to the problem.',
    subhead: 'Design Research',
    items: ['Reactjs', 'Vuejs', 'Svelte', 'Angular'],
  },
  {
    title: 'Build for the mobile world',
    subhead: 'Mobile App Development',
    image: 'trainingImage',
    desc:
      'Conducting Design Research allows our team to validate that the problem we’re solving for is worth the time and money to solve for it. It develops a fuller understanding of the problem for the team from the eyes of the people using the product. Having that validation and team understanding sets the stage for the team to find innovative solutions to the problem.',
    items: ['React Native', 'Kotlin', 'Swift', 'Flutter'],
  },
  {
    title: 'Build Production Ready application quickly',
    image: 'trainingImage',
    subhead: 'Product Development',
    desc:
      'Conducting Design Research allows our team to validate that the problem we’re solving for is worth the time and money to solve for it. It develops a fuller understanding of the problem for the team from the eyes of the people using the product. Having that validation and team understanding sets the stage for the team to find innovative solutions to the problem.',
    items: ['Elixir', 'Go Lang', 'Ruby', 'Node.js'],
  },
]

/**
 *
 */
interface ServiceBlock {
    image?: string
    subhead?: string
    title?: string
    desc?: string
    items?: any
}

const ServiceBlock = ({ image, subhead, title, desc, items }: ServiceBlock) => (
  <Box variant="styles.section" mt={3} sx={{ p: 0, m: 0 }}>
    <Flex>
      <Box pl={6} pt={6} mt={0} pr={6}>
        <Image width="100%" src={image} />
      </Box>
      <Box pt={6} pb={5} variant="styles.sectionBlock" sx={{ color: 'gray.0' }}>
        <Box pb={4}>
          <Text as="span" variant="text.shortTitle" sx={{ fontSize: 1, color: 'grays.0', mb: 0 }}>
            {subhead}
          </Text>
          <Text as="h2" variant="text.sectionTitle" sx={{ fontSize: 6, color: 'grays.9', mt: 0, pt: 1, lineHeight: 'heading', fontWeight: 'heading' }}>
            {title}
          </Text>
          <Text
            variant="text.sectionBody"
            sx={{ fontSize: 4, color: 'grays.6', lineHeight: 'body', fontWeight: 'thin' }}
          >
            {desc}
          </Text>
        </Box>

        <Text variant="text.shortTitle">What we do</Text>
        <Flex sx={{ flexDirection: 'column', bg: 'grays.1 ' }} mt={4} mb={3}>
          {items.map(s => (
            <Box sx={{ mb: 2, px: 4, py: 3 }}>
              <Text sx={{ color: 'primarytext' }}>{s}</Text>
            </Box>
          ))}
        </Flex>

        <Box mt={2}>
          <Text variant="text.boldLink">Read about {subhead}</Text>
        </Box>
      </Box>
    </Flex>
  </Box>
)

export interface RageStakePageProps {
  data: any
}

const RageStakePage: FC<RageStakePageProps> = ({ data }) => {
  const isSSR = typeof window === 'undefined'
  const context = useContext(WalletContext)
  // const [stake, setStake] = useState<boolean>(false)
  const dialog = useDialogState({ animated: true })

  const [isVisible, setIsVisible] = useState<boolean>(false)

  const onStake = () => {
    // setStake(1)
    setIsVisible(true)
  }

  const onUnStake = () => {
    // setStake(3)
    setIsVisible(true)
  }

  const onCloseStake = () => {
    // setStake(0)
    setIsVisible(false)
  }

  const closeMe = () => {
    dialog.hide
  }

  return (
    <PageFrame variant="box.plateTint" invert={false} alt={false} adjustHeight={'3rem'}>
      <Halo title="Servies" url="https://functionary.co/services" />
      <Box sx={{ mt: 8 }}>
        <Box sx={{ py: 2, maxWidth: '90ch', mx: 'auto', pt: 4, pb: 3 }}>
          <Container mb={5} sx={{ px: [5, 0, 0] }}>
            <Box mt={5}>
              {ServicesList.map((service: any) => (
                <ServiceBlock {...service}/>
              ))}
            </Box>
          </Container>
        </Box>
      </Box>
    </PageFrame>
  )
}

export default RageStakePage
